export const sendEmail = async (formData) => {
  const { name, email, phoneNumber, service, message } = formData;

  try {
    const response = await fetch('https://formspree.io/f/mqakdlko', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        phoneNumber,
        service,
        message,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to send message');
    }
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};